body, html {
  background-color: bisque;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
}

main {
  width: 100%;
  height: 100%;
}

main a, main a:visited, main :active {
  color: #000;
}

a.read-more {
  padding: .5em;
  display: block;
  position: absolute;
}

.github-fork-ribbon {
  font-size: .77rem;
  position: fixed;
  top: -30px;
  right: -30px;
}

.github-fork-ribbon:after {
  content: "GitHub";
}

@media only screen and (width >= 550px) {
  .github-fork-ribbon {
    font-size: 1rem;
    top: 0;
    right: 0;
  }

  .github-fork-ribbon:after {
    content: "Fork me on GitHub";
  }
}
/*# sourceMappingURL=index.8f5fa656.css.map */
